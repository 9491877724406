import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'

import BackgroundImage from 'gatsby-background-image';

const BackgroundSection = ({ className, children, fluid }) => {


  

      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={fluid}
          backgroundColor={`darkgray`}
          
        >
        <div style={{height: "85vh",position: "relative", x: "0", y: "0"}}>
        {children}
        </div>
        </BackgroundImage>
      )
}
  


const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
  
      
  
`

export default StyledBackgroundSection