import React from 'react';
import styled from 'styled-components';
import colors from './theme';
import Image from './image';

const footer = () => {

    const FooterSection = styled.section`
    height: 30vh;
    display: flex;
    width: 100vw;
    background-color: ${colors.gray};
    @media(max-width:1000px){
        //display: none;
        visibility: hidden;
        height: 40px;
    }
    `

    const LeftFooter = styled.div`
    width: 33%;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: flex-start;
    font-family: rubik, san-serif;
    color: #fff;
    @media(max-width: 1000px){
        display: none;
    }
    `
    const CenterFooter = styled.div`
    width: 33%;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    font-family: rubik, san-serif;
    color: #fff;
    @media(max-width: 1000px){
        width 100%;
        padding: 10px;
    }
    `

    const RightFooter = styled.div`
    width: 33%;
    display: flex;
    flex-direction: column; 
    align-items: flex-end;
    justify-content: center;
    font-family: rubik, san-serif;
    color: #fff;
    @media(max-width: 1000px){
        display: none;
    }
    `

    const LinkList = styled.ul`
    
    font-weight: 200;
    font-size: 18px;
    list-style-type: none;
    `

    const FooterLink = styled.li`
    text-decoration: none;
    margin: 5px;
    cursor: pointer;
    `

    const LogoText = styled.a`
  margin: 0.5em 2em 0.5em .25em;
  color: white;
  display: flex;
  text-transform: uppercase;
  font-size: 2em;
  font-family: rubik;
  font-weight: 600;
  vertical-align: center;
`

const StyledLink = styled.a`
text-decoration: none;
color: #fff;

`

const MainIcon = styled.a`
  align-items: center;
  display: flex;
  `

  const LogoDiv = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none`

  const MailingListLabel = styled.label`
  font-size: 16px;
  font-weight: 200;
  text-transform: uppercase;
  display: flex;
  flex-direction: column; 
  color: #fff;
  `

  const MailingListInput = styled.input`
  box-sizing:border-box;
  padding: .5em;
  height: 50px;
  width: 75%;
  display: flex;
  border: 2px solid ${colors.blue};
  border-radius: 3px;
  font-size: 20px;
  font-family: rubik, san-serif;
  outline: none;
  `


  const MailingListSubmit = styled.button`
  box-sizing:border-box;
  padding: 1em;
  width: 25%;
  height: 100%;
  background-color: ${colors.blue};
  color: #fff,
  text-transform: uppercase;
  border-radius: 3px;
  cursor: pointer;`
  
    return (
        <FooterSection>
            <LeftFooter>
                <LinkList>
                    <FooterLink href="https://www.riazcapital.com/press">Press</FooterLink>
                    <FooterLink href="https://www.riazcapital.com/careers">Careers</FooterLink>
                    <FooterLink><StyledLink href="/getintouch">Contact Us</StyledLink></FooterLink>
                    <FooterLink>Site Map</FooterLink>
                
                
                </LinkList>

            </LeftFooter>
            <CenterFooter>
                <MailingListLabel>
                    Interested in what we're doing? Join our mailing list!
                    <LogoDiv>

                        <MailingListInput type="email" placeholder="andy@squareone-living.com" />
                        <MailingListSubmit>Go</MailingListSubmit>
                
                    </LogoDiv>
                </MailingListLabel>
            </CenterFooter>
            <RightFooter>
                <LogoDiv href="/">
                    
                    <MainIcon><Image alt="Square One" /></MainIcon>
                    <LogoText>Square One</LogoText>
                    
                </LogoDiv>
             
              

            </RightFooter>

        </FooterSection>
    )
}

export default footer; 