import { Link, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Image from "./image"
import styled from 'styled-components'



const NavLink = styled.a`

  margin: 0 1.5em 0 1.5em;
  font-size: 1em;
  color: #D4F4EF;
  display: flex;
  align-items: center;
  text-decoration: none;
  &:hover {
    filter: brightness(0.6)
  }
  @media (max-width: 1000px){
    display: none;
  }
  
`

const MainIcon = styled.a`
  align-items: center;
  display: flex;
  `

const LogoText = styled.a`
  margin: 0.5em 2em 0.5em .25em;
  color: white;
  display: flex;
  text-transform: uppercase;
  font-size: 2em;
  font-family: rubik;
  font-weight: 600;
  vertical-align: center;
  @media (max-width: 600px){
    font-size: 1.5em;
  }
`

const NavBar = styled.nav`
  padding: 0em 0em 1em 0em;
  background: transparent;
  z-index: 5;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items; center;
  justify-content: center;
  list-style: none;
  position: absolute;
  font-family: rubik;
  @media (max-width: 1000px){
    justify-content: center;
  }
`

const MenuIconLineTop = styled.div`
  width: 35px;
  height: 3px;
  background-color: #fff;
  margin: 3px 0;
`

const MenuIconLineMiddle = styled.div`
  width: 35px;
  height: 3px;
  background-color: #fff;
  margin: 3px 0;
`
const MenuIconLineBottom = styled.div`
  width: 35px;
  height: 3px;
  background-color: #fff;
  margin: 3px 0;
`

const MenuIcon = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
`







const Header = ({ siteTitle,isOpen,clickHandler,hidemenu }) => {

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "squareone-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)


  const { fluid } = data.file.childImageSharp

  const tabs = [
    {
      title: "Our Mission",
      link: "/homes/"
    }, 
    {
        title: "Communities",
        link: "/amenities/"
     },
    { 
      title: "Spaces",
      link: "/studio/"
     },
     {
       title: "Get in touch",
       link: "/getintouch/"
     }
    ]

  return (
    <NavBar>

   {hidemenu ? null : <MenuIcon onClick= {()=>clickHandler()}>
        <MenuIconLineTop/>
        <MenuIconLineMiddle/>
        <MenuIconLineBottom/>
      </MenuIcon>}


      <MainIcon><Image alt="Square One" /></MainIcon>
      
      <LogoText><Link style={{textDecoration: "none", color: "#D4F4EF"}} to="/">{siteTitle}</Link></LogoText>

      {/* {tabs.map(({title,link}) => {
        return (
          <NavLink key={title}><Link style={{textDecoration: "none",fontSize: "1.25em",
          color: "#D4F4EF"}} to={link}>{title}</Link></NavLink>
        )
      })} */}
    </NavBar>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
