import React, {useState} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components'
import Header from "./header";

import Footer from './footer'
import StyledBackgroundSection from './backgroundimage';
import { createGlobalStyle } from "styled-components";
import colors from './theme';
import { animated, useSpring, config } from 'react-spring'
import { FaArrowLeft, FaChevronCircleDown } from 'react-icons/fa';


const GlobalStyles = createGlobalStyle`

html, body {
  max-width: 100% !important;
  overflow-x: hidden !important;
  font: Rubik, sans-serif;
}
  `

  
const MobileNav = styled(animated.div)`
height: 100%;
position: fixed;
border-radius: 0px 5px 5px 0px;
top: 0;
left: 0;
display: flex;
flex-direction: column;
align-items: center;
width: 300px;
z-index: 4000000;
max-width: 300px;
z-index: 40;
background-color: ${colors.pink};
box-shadow: 1px 2px 3px gray;
overflow: hidden;
`

const Overlay = styled.div`
z-index: 3000000;
width: 100%;
height: 100%;
overflow: hidden;

`

const ReturnDiv = styled.div`
width: 80%;
margin: 10px;
height: 30px;
border-radius: 5px;
display: flex;
align-items: center;
box-shadow: 1px 2px 3px lightgray;
overflow: hidden;
cursor: pointer;
;
`

const ReturnDivLeft = styled.div`
width: 30%;
display: flex;
height: 100%;
justify-content: center;
align-items: center;
background-color: #fff;
`

const ReturnDivRight = styled.div`
width: 70%;
display: flex;
justify-content: center;
align-items: center;
font-family: rubik;
font-size: 1em;
font-weight: 200;
text-transform: uppercase;
height: 100%;
background-color: ${colors.blue} 
`

const ComingSoon = styled.div`
width: 75px;
height: 20px;
margin-left: 10px;
background-color: ${colors.gray};
color: #FFF;
text-transform: uppercase;
font-weight: 400; 
font-size: 10px;
display: flex;
justify-content: center;
align-items: center;
border-radius: 3px;
padding: 3px;


`


const NavLink = styled.a`

  margin: 0 1.5em 0 1.5em;
  font-size: 1em;
  color: black;
  display: flex;
  text-align: center;
  font-family: rubik;
  cursor: pointer;
  height: 50px;
  width: 100%;
  border-bottom: 1px solid lightgray;
  padding-left: 10px;
  align-items: center;
  text-decoration: none;
  font-weight: 700;
  &:hover {
    filter: brightness(0.6)
  }
`

const NavSubLink = styled.a`

  margin: 0 1.5em 0 1.5em;
  font-size: 1em;
  color: black;
  display: flex;
  text-align: center;
  font-family: rubik;
  cursor: pointer;
  height: 50px;
  width: 100%;
  border-bottom: 1px solid lightgray;
  padding-left: 40px;
  font-weight: 200;
  align-items: center;
  text-decoration: none;
  &:hover {
    filter: brightness(0.6)
  }
`

const NavSubLinkInactive = styled.a`

  margin: 0 1.5em 0 1.5em;
  font-size: 1em;
  color: black;
  display: flex;
  text-align: center;
  font-family: rubik;
  height: 50px;
  width: 100%;
  cursor: default;
  border-bottom: 1px solid lightgray;
  padding-left: 40px;
  font-weight: 200;
  align-items: center;
  text-decoration: none;
  
`


const Layout = ({ children,hidemenu }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [isOpen, setIsOpen] = useState(false);
  const [communitiesOpen, setCommunitiesOpen] = useState(false);

  const clickHandler = () => {
    setIsOpen(!isOpen);
  }

  const communitiesClickHandler = () => {
    setCommunitiesOpen(!communitiesOpen);
  }

  const MainDiv = styled.div`
    width: 100%;
    height: 100vh;
    
  `

  const { title } = data.site.siteMetadata

  //animations

  //navigation

  const props = useSpring({marginLeft: isOpen ? 0 : -305})


  return (
    
  <>

  <GlobalStyles />
      
      <Header siteTitle={title} hidemenu={hidemenu} isOpen={isOpen} clickHandler={clickHandler}/>
      {isOpen && 
      
      <MobileNav style={props}>
        <ReturnDiv onClick={()=> clickHandler()}>
          <ReturnDivLeft>
            <FaArrowLeft />
          </ReturnDivLeft>
          <ReturnDivRight>
            Back
          </ReturnDivRight>
        </ReturnDiv>
        <NavLink onClick = {()=> communitiesClickHandler()}>Communities <FaChevronCircleDown style={{padding: "10px"}} /></NavLink>
            {communitiesOpen &&
            <>
            <NavSubLink href='/the-linden'>The Linden</NavSubLink>
            <NavSubLink href='/northgate'>27th &amp; Northgate</NavSubLink>
            <NavSubLinkInactive href='/820-w-macarthur'>820 W MacArthur<ComingSoon>Coming Soon</ComingSoon></NavSubLinkInactive>
            </>
            }
        {/* <NavLink href='/blog'>Blog</NavLink>     */}
        <NavLink href='/getintouch'>Reach Out</NavLink>
        <NavLink href='/'>Home</NavLink>
        <NavLink href='/application'>Apply Now!</NavLink>
        
        
      </MobileNav>
      
      
          }
    
        <main>{children}</main>
   
      <Footer />
   </>
      
    
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
